<template>
  <div class="flex flex-col">
    <nav class="flex flex-row px-3 mt-3 pb-2 border-b border-blue-300 items-end">
      <v-back :fallback="$router.resolve({ name: 'teams-overview'})"/>
      <p
        v-if="team !== null"
        v-text="team.name"
        class="font-semibold w-full text-center -ml-7 pointer-events-none"
      ></p>
    </nav>
    <div class="flex-grow flex flex-col">
      <div class="ml-4 py-3 pr-3 border-b border-blue-500 flex items-center">
        <button
          v-if="searchValue"
          @click="back"
          class="mr-2">
          <img
            src="@/assets/images/chevron_left.svg"
            class="h-4 w-4">
        </button>
        <form
          @submit.prevent="search"
          class="w-full">
          <input
            type="text"
            v-model="searchValue"
            :placeholder="$t('the_team_detail.placeholder_search_users')"
            class="form-input-blocked"
          >
        </form>
        <v-dropdown
          class="ml-2"
          v-if="team !== null && team.canAddUser">
          <template slot="button">
            <img
              src="@/assets/images/plus.svg"
              class="h-7 w-7"
            />
          </template>
          <template slot="menu">
            <ul class="bg-white">
              <li>
                <router-link
                  class="block px-2 py-1 text-blue-500 text-center border-b border-blue-500
                    hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                  :to="{name: 'invite', query: {teamId: id}}"
                  v-text="$t('the_team_detail.button_invite_user')"
                >
                </router-link>
              </li>
              <li>
                <router-link
                  class="block px-2 py-1 text-blue-500 text-center
                    hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                  :to="{name: 'team-user-attach', params: {teamId: id}}"
                  v-text="$t('the_team_detail.button_attach_user')"
                >
                </router-link>
              </li>
            </ul>
          </template>
        </v-dropdown>
      </div>
      <v-scrollable
        v-if="!loading && !error"
        ref="scrollable">
        <users-list
          v-if="users !== null"
          :users="users"/>
      </v-scrollable>
      <v-loading v-if="loading"/>
      <v-error
        v-if="error"
        v-model="error"/>
    </div>
  </div>
</template>

<script>
  import debounce from 'lodash.debounce';
  import infinityScroll from '@/mixins/infinityScroll';
  import VError from '@/components/common/VError.vue';
  import VScrollable from '@/components/common/VScrollable.vue';
  import VLoading from '@/components/common/VLoading.vue';
  import UsersList from '@/components/lists/UsersList.vue';
  import VDropdown from '@/components/common/VDropdown.vue';
  import VBack from '@/components/common/VBack.vue';

  export default {
    name: 'TheTeamDetail',
    components: {
      VScrollable,
      VLoading,
      VError,
      UsersList,
      VDropdown,
      VBack,
    },
    mixins: [infinityScroll],
    props: {
      id: { type: String, default: null },
    },
    data() {
      return {
        loading: true,
        error: null,
        searchValue: null,
      };
    },
    computed: {
      team() {
        return this.$store.getters['team/item'];
      },
      users() {
        return this.$store.getters['user/list'] || this.team.users;
      },
      event() {
        return this.$store.getters['auth/event'];
      },
    },
    created() {
      this.loadTeam();

      this.$watch('searchValue', debounce(this.search, 500));
    },
    beforeDestroy() {
      this.$store.commit('team/clearItem');
      this.$store.commit('user/clearList');
    },
    methods: {
      loadTeam() {
        this.loading = true;

        this.$store
          .dispatch('team/loadById', this.id)
          .then(() => {
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.error = error;
          });
      },
      search() {
        this.error = null;

        this.detachInfiniteScroll();

        this.$store.commit('user/clearList');

        if (!this.searchValue) {
          this.loading = false;

          return;
        }

        this.loading = true;
        this.loadUsers();
      },
      loadUsers() {
        this.$store
          .dispatch('user/searchForTeam', {
            teamId: this.team.id,
            searchValue: this.searchValue,
          })
          .then(() => {
            this.loading = false;

            this.$nextTick(() => {
              this.attachInfiniteScroll(this.$refs.scrollable, () => {
                this.loadUsers();
              });
            });
          })
          .catch((error) => {
            this.loading = false;
            this.error = error;
          });
      },
      back() {
        this.searchValue = null;
      },
    },
  };
</script>
