<template>
  <div class="group relative">
    <slot name="button"></slot>
    <div class="dropdown hidden group-hover:block absolute right-0 z-50 pt-1">
      <div class="menu w-48 shadow-lg rounded-lg border border-gray-200">
        <div class="rounded-lg overflow-hidden">
          <slot name="menu"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'VDropdown',
  };
</script>
