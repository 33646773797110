<template>
  <div>
    <a
      :href="$store.getters['routing/last']"
      @click.prevent="back"
      :title="!close ? $t('general.button_back') : $t('general.button_close')"
      class="btn-round flex items-center">
      <img
        v-if="!close"
        src="@/assets/images/back.svg"
        :alt="$t('general.button_back')"
        class="w-7 h-7"
      >
      <img
        v-if="close"
        src="@/assets/images/close.svg"
        :alt="$t('general.button_close')"
        class="w-7 h-7"
      >
    </a>
  </div>
</template>

<script>
  export default {
    name: 'VBack',
    props: {
      close: {
        type: Boolean,
        default: false,
      },
      fallback: {
        type: Object,
        default: null,
      },
    },
    created() {
      const previous = this.$store.getters['routing/last'];

      if (!previous || previous === '/') {
        this.$store.commit('routing/push', this.fallback.href);
      }
    },
    methods: {
      back() {
        this.$router.push(this.$store.getters['routing/last']);
      },
    },
  };
</script>
