var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "a",
      {
        staticClass: "btn-round flex items-center",
        attrs: {
          href: _vm.$store.getters["routing/last"],
          title: !_vm.close
            ? _vm.$t("general.button_back")
            : _vm.$t("general.button_close")
        },
        on: {
          click: function($event) {
            $event.preventDefault()
            return _vm.back($event)
          }
        }
      },
      [
        !_vm.close
          ? _c("img", {
              staticClass: "w-7 h-7",
              attrs: {
                src: require("@/assets/images/back.svg"),
                alt: _vm.$t("general.button_back")
              }
            })
          : _vm._e(),
        _vm.close
          ? _c("img", {
              staticClass: "w-7 h-7",
              attrs: {
                src: require("@/assets/images/close.svg"),
                alt: _vm.$t("general.button_close")
              }
            })
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }