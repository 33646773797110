var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "group relative" },
    [
      _vm._t("button"),
      _c(
        "div",
        {
          staticClass:
            "dropdown hidden group-hover:block absolute right-0 z-50 pt-1"
        },
        [
          _c(
            "div",
            {
              staticClass:
                "menu w-48 shadow-lg rounded-lg border border-gray-200"
            },
            [
              _c(
                "div",
                { staticClass: "rounded-lg overflow-hidden" },
                [_vm._t("menu")],
                2
              )
            ]
          )
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }